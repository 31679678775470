@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-50;
  }

  .button {
    @apply inline-block uppercase tracking-wide text-sm  font-semibold bg-indigo-500 pl-5 pr-5 pt-3 pb-3 m-2 text-center text-white rounded-sm transition-colors hover:bg-indigo-700;
  }

  .button-small {
    @apply button text-xs px-3 py-1 my-1 mx-0.5;
  }

  .input {
    @apply inline-block py-2 px-4 text-lg shadow-sm rounded-sm border border-gray-300 border-solid focus:outline-none focus:ring-2;
  }

  .error {
    @apply text-white bg-red-400 border border-red-600 my-8 px-3 py-2 rounded-sm text-lg font-bold;
  }
}

@layer utilities {
  .w-300px {
    width: 300px;
  }
}
